<template>
	<div class="stats">
		<div>
			<div>
				<header>Users Created</header>
				<div>
					<span>This month:</span><span>{{ stats.accountsCreatedThisMonth }}</span>
				</div>
				<div>
					<span>This month prev year:</span><span>{{ stats.accountsCreatedThisMonthPreviousYear }}</span>
				</div>
				<div>
					<span>This year:</span><span>{{ stats.accountsCreatedThisYear }}</span>
				</div>
				<div>
					<span>Prev year:</span><span>{{ stats.accountsCreatedPreviousYear }}</span>
				</div>
				<div>
					<span>This month, still open:</span><span>{{ stats.accountsCreatedThisMonthStillOpen }}</span>
				</div>
				<div>
					<span>This month, closed:</span><span>{{ stats.accountsCreatedThisMonthNowClosed }}</span>
				</div>
				<div>
					<span>This year, still open:</span><span>{{ stats.accountsCreatedThisYearStillOpen }}</span>
				</div>
				<div>
					<span>This year, closed:</span><span>{{ stats.accountsCreatedThisYearNowClosed }}</span>
				</div>
			</div>
			<div>
				<header>Active (by login), Open Users</header>
				<div>
					<span>This month:</span><span>{{ stats.activeAccountsThisMonth }}</span>
				</div>
				<div>
					<span>Last six months:</span><span>{{ stats.activeAccountsThisSixMonths }}</span>
				</div>
				<div>
					<span>This year:</span><span>{{ stats.activeAccountsThisYear }}</span>
				</div>
			</div>
			<div>
				<header>Unique User Logins</header>
				<div>
					<span>This month:</span><span>{{ stats.uniqueLoginsThisMonth }}</span>
				</div>
				<div>
					<span>This month prev year:</span><span>{{ stats.uniqueLoginsThisMonthPreviousYear }}</span>
				</div>
				<div>
					<span>This year:</span><span>{{ stats.uniqueLoginsThisYear }}</span>
				</div>
				<div>
					<span>Prev year:</span><span>{{ stats.uniqueLoginsPreviousYear }}</span>
				</div>
			</div>
			<div>
				<header>Total User Logins</header>
				<div>
					<span>This month:</span><span>{{ stats.totalLoginsThisMonth }}</span>
				</div>
				<div>
					<span>This month prev year:</span><span>{{ stats.totalLoginsThisMonthPreviousYear }}</span>
				</div>
				<div>
					<span>This year:</span><span>{{ stats.totalLoginsThisYear }}</span>
				</div>
				<div>
					<span>Prev year:</span><span>{{ stats.totalLoginsPreviousYear }}</span>
				</div>
			</div>
			<div>
				<header>Total Set Prints</header>
				<div>
					<span>This month:</span><span>{{ stats.totalSetPrintsThisMonth }}</span>
				</div>
				<div>
					<span>This month prev year:</span><span>{{ stats.totalSetPrintsThisMonthPreviousYear }}</span>
				</div>
				<div>
					<span>This year:</span><span>{{ stats.totalSetPrintsThisYear }}</span>
				</div>
				<div>
					<span>Prev year:</span><span>{{ stats.totalSetPrintsPreviousYear }}</span>
				</div>
			</div>
			<div>
				<header>Total Set Emails</header>
				<div>
					<span>This month:</span><span>{{ stats.totalSetEmailsThisMonth }}</span>
				</div>
				<div>
					<span>This month prev year:</span><span>{{ stats.totalSetEmailsThisMonthPreviousYear }}</span>
				</div>
				<div>
					<span>This year:</span><span>{{ stats.totalSetEmailsThisYear }}</span>
				</div>
				<div>
					<span>Prev year:</span><span>{{ stats.totalSetEmailsPreviousYear }}</span>
				</div>
			</div>
			<div>
				<header>Total Users</header>
				<div>
					<span>Open Students:</span><span>{{ stats.totalOpenStudents }}</span>
				</div>
				<div>
					<span>Open Sole Practitioners:</span><span>{{ stats.totalOpenSolePractitioners }}</span>
				</div>
				<div>
					<span>Open Staff:</span><span>{{ stats.totalOpenStaff }}</span>
				</div>
				<div>
					<span>Closed Students:</span><span>{{ stats.totalClosedStudents }}</span>
				</div>
				<div>
					<span>Closed Sole Practitioners:</span><span>{{ stats.totalClosedSolePractitioners }}</span>
				</div>
				<div>
					<span>Closed Staff:</span><span>{{ stats.totalClosedStaff }}</span>
				</div>
			</div>
			<div>
				<header>Total Organizations</header>
				<div>
					<span>Open:</span><span>{{ stats.totalOpenOrgs }}</span>
				</div>
				<div>
					<span>Seats:</span><span>{{ stats.totalSeats }}</span>
				</div>
				<div>
					<span>Closed:</span><span>{{ stats.totalClosedOrgs }}</span>
				</div>
			</div>
			<div>
				<header>Clients</header>
				<div>
					<span>Unique logins this month:</span><span>{{ stats.uniqueClientLoginsThisMonth }}</span>
				</div>
				<div>
					<span>Unique logins this year:</span><span>{{ stats.uniqueClientLoginsThisYear }}</span>
				</div>
				<div>
					<span>Total logins this month:</span><span>{{ stats.totalClientLoginsThisMonth }}</span>
				</div>
				<div>
					<span>Total logins this year:</span><span>{{ stats.totalClientLoginsThisYear }}</span>
				</div>
				<div>
					<span>Premium upgrades this month:</span
					><span>{{ stats.totalClientPremiumUpgradesThisMonth }}</span>
				</div>
				<div>
					<span>Premium upgrades this year:</span><span>{{ stats.totalClientPremiumUpgradesThisYear }}</span>
				</div>
			</div>
			<div>
				<header>Top 50 Logins (Last 3 Months)</header>
				<div v-for="(user, indx) in stats.topLogins" :key="indx">
					<router-link :to="`/users/${user.userId}`"
						>{{ indx + 1 }}. {{ user.firstname }} {{ user.lastname }} ({{ user.logins }})</router-link
					>
				</div>
			</div>
			<div>
				<header>Top 50 Printers (Last 3 Months)</header>
				<div v-for="(user, indx) in stats.topPrints" :key="indx">
					<router-link :to="`/users/${user.userId}`"
						>{{ indx + 1 }}. {{ user.firstname }} {{ user.lastname }} ({{ user.prints }})</router-link
					>
				</div>
			</div>
			<div>
				<header>Top 50 Emailers (Last 3 Months)</header>
				<div v-for="(user, indx) in stats.topEmails" :key="indx">
					<router-link :to="`/users/${user.userId}`"
						>{{ indx + 1 }}. {{ user.firstname }} {{ user.lastname }} ({{ user.emails }})</router-link
					>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { ref, onMounted } from "vue"
	import { api } from "@/store"

	export default {
		name: "Stats",
		components: {},
		props: {},
		setup(props) {
			const stats = ref({})
			onMounted(async () => {
				stats.value = await api.get(`admin/stats`)
			})
			return {
				stats
			}
		}
	}
</script>

<style lang="scss">
	.stats {
		> div {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			grid-gap: $atom * 5;
			> div {
				background: #fff;
				padding: $atom * 5;
				border-radius: 7px;
				padding-top: 0;
				header {
					position: sticky;
					top: -$atom * 5;
					background: #fff;
					font-size: $fnt-lrg;
					color: $clr-prim;
					padding: $atom * 5 0;
				}
				> div {
					display: flex;
					align-items: center;
					gap: $atom * 5;
					> span {
						flex: 1;
						white-space: nowrap;
						&:first-child {
							text-align: right;
						}
					}
				}
			}
		}
	}
</style>
